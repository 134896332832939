import { DisplayNames } from 'Roblox';
import { concatTexts } from 'core-utilities';
import recommendationsModule from '../recommendationsModule';

function creatorNameUtilities($filter, itemListConstants) {
  'ngInject';

  return {
    mapItemRestrictionIcons(item) {
      if (item && item.itemRestrictions) {
        const { itemTypes, itemRestrictionTypes, itemRestrictionIcons } = itemListConstants;
        if (item.itemType === itemTypes.bundle) {
          item.isLimited = item.itemRestrictions.indexOf(itemRestrictionTypes.limited) > -1;
          item.isCollectible = item.itemRestrictions.indexOf(itemRestrictionTypes.collectible) > -1;
          item.isRthro = item.itemRestrictions.indexOf(itemRestrictionTypes.rthro) > -1;
          item.isDynamicHead = item.itemRestrictions.indexOf(itemRestrictionTypes.dynamicHead) > -1;
          if (item.isLimited) {
            item.itemRestrictionIcon = item.isRthro
              ? itemRestrictionIcons.rthroLimitedLabel
              : itemRestrictionIcons.rthroLabel;
          } else if (item.isDynamicHead) {
            item.itemRestrictionIcon = itemRestrictionIcons.dynamicHead;
          } else if (item.isCollectible) {
            item.itemRestrictionIcon = itemRestrictionIcons.collectible;
          }
        } else {
          item.isThirteenPlus =
            item.itemRestrictions.indexOf(itemRestrictionTypes.thirteenPlus) > -1;
          item.isLimitedUnique =
            item.itemRestrictions.indexOf(itemRestrictionTypes.limitedUnique) > -1;
          item.isLimited = item.itemRestrictions.indexOf(itemRestrictionTypes.limited) > -1;
          item.isCollectible = item.itemRestrictions.indexOf(itemRestrictionTypes.collectible) > -1;
          item.isDynamicHead = item.itemRestrictions.indexOf(itemRestrictionTypes.dynamicHead) > -1;
          if (item.isLimitedUnique) {
            item.itemRestrictionIcon = item.isThirteenPlus
              ? itemRestrictionIcons.thirteenPlusLimitedUnique
              : itemRestrictionIcons.limitedUnique;
          } else if (item.isLimited) {
            item.itemRestrictionIcon = item.isThirteenPlus
              ? itemRestrictionIcons.thirteenPlusLimited
              : itemRestrictionIcons.limited;
          } else if (item.isThirteenPlus) {
            item.itemRestrictionIcon = itemRestrictionIcons.thirteenPlus;
          } else if (item.isDynamicHead) {
            item.itemRestrictionIcon = itemRestrictionIcons.dynamicHead;
          } else if (item.isCollectible) {
            item.itemRestrictionIcon = itemRestrictionIcons.collectible;
          }
        }
      }
    },

    getNameForDisplay: creator => {
      const { userTypes, systemRobloxId } = itemListConstants;
      const { name, creatorType, creatorId } = creator;
      return userTypes[1] === creatorType && systemRobloxId !== creatorId && DisplayNames.Enabled()
        ? concatTexts.concat(['', $filter('escapeHtml')(name)])
        : $filter('escapeHtml')(name);
    }
  };
}

recommendationsModule.factory('creatorNameUtilities', creatorNameUtilities);
export default creatorNameUtilities;
